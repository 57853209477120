<template>
  <v-col>
    <CyNotification
      theme="error"
      :content="errors"/>
    <CyNotification
      v-if="_.isEmpty(configRepository) && $cycloid.permissions.canDisplay('GetConfigRepository')"
      class="mx-n3"
      theme="warning"
      :title="$t('missingConfigRepo')"
      :content="$t('missingConfigRepoInfo')"
      :button-label="$t('addConfigRepo')"
      @click="$router.push({ ...$route, name: 'projectConfiguration' })"/>
    <v-row class="environments-wrapper height-100">
      <v-col
        class="layout-general pl-0"
        cols="12"
        md="7"
        lg="8"
        xl="9">
        <CyEnvironmentListTable
          :environments="environments"
          :project-canonical="projectCanonical"
          :config-repository="configRepository"
          :loading="status.loadingPipelines || status.loadingEnvironments"
          @start="startEnvironment"
          @stop="stopEnvironment"
          @delete="onDeleteButtonClick"/>
      </v-col>
      <v-col
        class="side-column overflow-hidden"
        cols="12"
        md="5"
        lg="4"
        xl="3">
        <h2 class="content-title mb-2 font-size-base font-weight-bold">
          {{ $t('Stack') }}
        </h2>
        <div
          v-if="loadingStack"
          class="d-flex flex-column sk-block pa-4 mb-5 space-y-7">
          <div class="d-flex space-x-4">
            <div class="sk-block sk-dark sk-h-8 sk-w-24"/>
            <div class="sk-block sk-dark sk-h-8 sk-full-width"/>
            <div class="sk-block sk-h-8 sk-dark sk-w-24"/>
          </div>
          <div class="sk-block sk-dark sk-h-6 sk-full-width"/>
        </div>
        <CyWizardServiceCard
          v-else
          class="mb-5"
          show-details-btn
          :details-btn-action="() => { previewedStack = stack }"
          :service="stack"/>

        <h2
          v-has-rights-to="'GetEvents'"
          class="content-title mb-2 font-size-base font-weight-bold">
          {{ $t('recentActivity') }}
        </h2>
        <CyEventsRecentActivityCard
          v-has-rights-to="'GetEvents'"
          :show-card-title="false"
          :loading="status.loadingEvents"
          :disabled="status.loadingEvents"
          :events="events"
          :all-events-route="{ name: 'projectActivity', params: { orgCanonical } }"
          :event-filters.sync="eventFilters"/>
      </v-col>

      <CyFormsEnvironmentDelete
        v-if="environmentToDelete"
        :environment="environmentToDelete"
        :project-canonical="projectCanonical"
        @cancel="onCancelButtonClick"
        @success="onDeleteSuccess"/>
    </v-row>
    <portal
      v-if="previewedStack"
      to="side-panel">
      <div class="side-panel-backdrop"/>
      <v-slide-x-reverse-transition>
        <v-card
          v-click-outside="{
            handler: closePreview,
            include: getClickOutsideExceptions,
          }"
          aria-label="Stack preview"
          role="region"
          class="side-panel">
          <CyStackPreview
            :stack="previewedStack"
            :show-select-btn="false"
            @close="closePreview"/>
        </v-card>
      </v-slide-x-reverse-transition>
    </portal>
  </v-col>
</template>

<script>
import { constructBreadcrumb, extractStartStopPipelines } from '@/utils/helpers'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import CyEnvironmentListTable from '@/components/environment/list-table'
import CyEventsRecentActivityCard from '@/components/events/recent-activity-card'
import CyFormsEnvironmentDelete from '@/components/forms/environment-delete'
import CyWizardServiceCard from '@/components/wizard/service-card'
import CyStackPreview from '@/components/stack-preview'

const defaultEventProps = {
  itemsPerPage: 50,
  sortBy: ['timestamp'],
  sortDesc: [true],
}

export default {
  name: 'CyPageEnvironments',
  components: {
    CyEnvironmentListTable,
    CyEventsRecentActivityCard,
    CyFormsEnvironmentDelete,
    CyWizardServiceCard,
    CyStackPreview,
  },
  breadcrumb () {
    const { projectCanonical, projectName } = this
    return constructBreadcrumb(this.$options.name, this.$t('routes.projectEnvironments'), [
      {
        label: projectName,
        name: 'project',
        params: { projectCanonical },
      },
      {
        label: this.$t('routes.projectsSection'),
        name: 'projectsSection',
      },

    ])
  },
  props: {
    projectCanonical: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    apiQueriesErrors: [],
    environmentToDelete: null,
    environments: [],
    previewedStack: null,
    status: {
      loadingPipelines: false,
      loadingEnvironments: false,
      loadingEvents: false,
      deleting: false,
    },
    show: {
      deleteDialog: false,
    },
  }),
  computed: {
    ...mapState('organization', {
      events: (state) => state.available.events,
    }),
    ...mapState('organization/project', {
      pipelines: (state) => state.pipelines,
      pipelinesErrors: (state) => state.errors.pipelines,
      projectErrors: (state) => state.errors,
    }),
    ...mapState('organization/stack', {
      loadingStack: (state) => state.fetchInProgress.stack,
    }),
    ...mapGetters('layout', [
      'getDataTableFilters',
      'getDataTableProps',
    ]),
    ...mapGetters('organization/project', [
      'envs',
      'hasEnvs',
      'stackRef',
    ]),
    ...mapGetters('organization/stack', [
      'stack',
    ]),
    ...mapGetters('organization/project/configRepository', [
      'configRepository',
    ]),
    errors () {
      return [
        ...this.apiQueriesErrors,
        ...this.pipelinesErrors,
      ]
    },
    eventProps: {
      get () {
        return this.getDataTableProps(this.$route.name)
      },
      set (props) {
        this.SET_DATA_TABLE_PROPS({ name: this.$route.name, props })
      },
    },
    eventFilters: {
      get () {
        const { $route, projectCanonical } = this
        return this.getDataTableFilters(`${$route.name}:${projectCanonical}`)
      },
      async set (filters) {
        const { $route, projectCanonical } = this

        this.SET_DATA_TABLE_FILTERS({ name: `${$route.name}:${projectCanonical}`, filters })
        this.getEvents()
      },
    },
    canSeeEvents () {
      return this.$cycloid.permissions.canDisplay('GetEvents')
    },
  },
  watch: {
    envs (newValue) {
      if (!_.isEmpty(newValue)) this.fetchStartStopPipelines()
    },
  },
  async created () {
    await this.GET_STACK({ stackRef: this.stackRef })
    if (this.canSeeEvents) {
      await this.FETCH_AVAILABLE({ keyPath: 'tags' })
    }
    this.eventFilters = _.merge({}, this.eventFilters, this.getDefaultEventFilters())
    this.eventProps = _.merge({}, this.eventProps, defaultEventProps, { filters: this.eventFilters })
    this.getEvents()
  },
  async mounted () {
    if (this.hasEnvs) this.fetchStartStopPipelines()
    if (this.$cycloid.permissions.canDisplay('ListConfigRepositories')) {
      await this.FETCH_AVAILABLE({ keyPath: 'configRepositories' })
    }
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapMutations('layout', [
      'SET_DATA_TABLE_FILTERS',
      'SET_DATA_TABLE_PROPS',
    ]),
    ...mapActions('organization/project', [
      'GET_PROJECT_PIPELINES',
    ]),
    ...mapActions('organization/stack', [
      'GET_STACK',
    ]),
    ...mapActions('alerts', [
      'SHOW_ALERT',
    ]),
    async getEvents () {
      this.status.loadingEvents = true
      if (this.canSeeEvents) {
        await this.FETCH_AVAILABLE({
          keyPath: 'events',
          extraParams: [this.eventFilters],
        })
      }
      this.status.loadingEvents = false
    },
    getDefaultEventFilters () {
      const nowTimestamp = Date.now()
      const sevenDaysAgoTimestamp = $date.subDays(nowTimestamp, 7)
      const begin = $date.format(sevenDaysAgoTimestamp, 'T')
      const end = $date.format(nowTimestamp, 'T')

      const filters = {
        'project_canonical[in]': this.projectCanonical,
        begin,
        end,
      }

      return filters
    },
    getUsecase (env) {
      return _.find(this.envs, (envList) => envList.canonical === env)
    },
    getClickOutsideExceptions () {
      const selectors = [
        '.main-nav a',
        '.main-nav button',
        '.dev-locale-switcher__options',
        '.dev-layer',
        '.v-menu__content',
      ]
      return Array.from(document.querySelectorAll(selectors.join(', ')))
    },
    onDeleteButtonClick (environment) {
      this.environmentToDelete = environment
      this.show.deleteDialog = true
    },
    onCancelButtonClick () {
      this.environmentToDelete = null
      this.show.deleteDialog = false
    },
    onDeleteSuccess () {
      if (!this.hasEnvs && _.isEmpty(this.projectErrors.env)) this.$router.push({ name: 'projects' })
      else {
        this.show.deleteDialog = false
        this.environmentToDelete = null
      }
    },
    async stopEnvironment (env) {
      _.set(env, 'startStop.stopping', true)
      await this.startStopEnvironment(env, 'stop')
      _.set(env, 'startStop.stopping', false)
    },
    async startEnvironment (env) {
      _.set(env, 'startStop.starting', true)
      await this.startStopEnvironment(env, 'start')
      _.set(env, 'startStop.starting', false)
    },
    async startStopEnvironment (env, type) {
      this.apiQueriesErrors = []
      const pipelineCanonical = `start-stop-${this.projectCanonical}-${env.canonical}`
      const { data, errors } = await this.$cycloid.ydAPI.createBuild(this.orgCanonical, this.projectCanonical, pipelineCanonical, type) || {}
      if (data) {
        const successMessage = type === 'start' ? 'started' : 'stopped'
        this.SHOW_ALERT({ type: 'success', content: this.$t(`alerts.success.environment.${successMessage}`) })
      }
      if (errors) this.apiQueriesErrors = errors
    },
    async fetchStartStopPipelines () {
      this.apiQueriesErrors = []
      this.status.loadingPipelines = true
      let startStopPipelines = []

      await this.GET_PROJECT_PIPELINES()
      if (!_.isEmpty(this.pipelines)) startStopPipelines = extractStartStopPipelines(this.pipelines)?.startStop

      this.environments = this.envs.map((env) => {
        const { canonical, use_case: useCase, icon, color } = env
        const pipeline = startStopPipelines.find(({ environment }) => environment.canonical === canonical)
        const pipelineStatus = this.pipelines.find(({ environment }) => environment.canonical === canonical)?.status

        return {
          canonical,
          useCase,
          icon,
          color,
          cloudProviderName: this.getUsecase(canonical)?.cloud_provider_name,
          cloudProviderCanonical: this.getUsecase(canonical)?.cloud_provider_canonical,
          pipelineStatus,
          ...(pipeline
            ? {
                startStop: {
                  enabled: !pipeline.paused,
                  starting: false,
                  stopping: false,
                  toggling: false,
                },
              }
            : {}
          ),
        }
      })

      this.status.loadingPipelines = false
    },
    closePreview () {
      this.previewedStack = null
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.projectEnvironments',
        addConfigRepo: 'Add Config Repository',
        confirmDelete: {
          header: {
            andProject: ' and project',
          },
        },
        missingConfigRepo: 'Missing Config Repository',
        missingConfigRepoInfo: 'In order to add, clone and configure environments, you need to supply a config repository.',
        recentActivity: 'Recent activity',
      },
      es: {
        title: '@:routes.projectEnvironments',
        addConfigRepo: 'Añadir repositorio de configuración',
        confirmDelete: {
          header: {
            andProject: ' y proyecto',
          },
        },
        missingConfigRepo: 'Repositorio de configuración faltante',
        missingConfigRepoInfo: 'Para agregar, clonar y configurar entornos, debe proporcionar un repositorio de configuración.',
        recentActivity: 'Actividad recientes',
      },
      fr: {
        title: '@:routes.projectEnvironments',
        addConfigRepo: 'Ajouter une source de configuration',
        confirmDelete: {
          header: {
            andProject: ' et le projet',
          },
        },
        missingConfigRepo: 'Source de configuration manquante',
        missingConfigRepoInfo: 'Pour ajouter, cloner et configurer des environnements, vous devez fournir un référentiel de configuration.',
        recentActivity: 'Activité récente',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.v-card > :first-child:not(.v-chip) {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

::v-deep .stack-card__description {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.cy-recent-events {
  max-height: 640px;
  min-height: 240px;
}

.side-column {
  display: flex;
  flex-direction: column;
  max-height: calc(95vh - 191px - 24px); // screen height minus header and its margins
}

.environments-wrapper {
  padding-bottom: 40px;

  @media screen and (min-width: 960px) {
    padding-bottom: 0;
  }

  &__column {
    height: 100%;
    padding-right: 0;
  }
}

.side-panel {
  $offset: 8px;

  display: flex;
  position: fixed;
  z-index: 110;
  top: $offset;
  right: $offset;
  flex-direction: column;
  width: 830px;
  height: calc(100% - #{$offset} * 2);

  &-backdrop {
    position: fixed;
    z-index: 108;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0 0 0 / 50%);
  }
}
</style>
